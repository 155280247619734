import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ "click" ]

  click() {
    this.clickTargets.forEach(target => target.click())
  }

  delayClick() { // This is for slim select, but there must be a better way
    setTimeout(() => {
      this.click()
    }, "100");
  }
}
