import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

export default class extends Controller {
  static values = {
    limit: Number,
    placeholder: { type: String, default: "Select Value" },
    searchText: { type: String, default: "No Results" }
  }

  connect() {
    const closeOnSelect = this.single
    const allowDeselect = !this.element.required

    if (this.single) {
      this.addPlaceholderDataAttribute()
    }

    this.select = new SlimSelect({
      select: this.element,
      closeOnSelect: closeOnSelect,
      allowDeselect: allowDeselect,
      limit: this.limitValue,
      onChange: this.update,
      placeholder: this.placeholderValue,
      searchText: this.searchTextValue
    })
  }

  addPlaceholderDataAttribute() {
    this.element[0].setAttribute("data-placeholder", "true");
  }

  toggleSelected(event) {
    event.preventDefault()
    const { checked } = event.detail.content

    this.select.data.data.forEach(option => {
      option.selected = checked
    })

    this.select.render()
  }

  update() {
    document.dispatchEvent(new Event("slim-select-updated"))
  }

  get single() {
    return !this.element.multiple
  }

  get multi() {
    return this.element.multiple
  }

  disconnect() {
    if (this.select) {
      this.select.destroy()
    }
  }
}

