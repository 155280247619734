import { Controller } from "@hotwired/stimulus"
import { Chart, registerables } from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
Chart.register(ChartDataLabels);
Chart.register(...registerables);


// Connects to data-controller="chart"
export default class extends Controller {
  static values = {
    chartData: Object,
    dataTypeFunctionName: String,
    configTypeFunctionName: String
  }

  connect() {
    const data = this[this.dataTypeFunctionNameValue](this.chartDataValue);
    const config = this[this.configTypeFunctionNameValue](data)

    const ctx = this.element
    const myChart = new Chart(ctx, config);
  }

  initializePieChartWithPercentagesData(data) {
    return {
      labels: data.labels,
      datasets: [{
        label: data.label,
        data: data.data,
        backgroundColor: data.colors,
        hoverOffset: 3
      }]
    };
  }

  initializePieChartWithPercentagesConfig(data) {
    // Expects data to be raw percentages, i.e. 0.745
    // These options will format them to 75% for tooltips and labels
    return {
      type: 'pie',
      data: data,
      responsive: true,
      plugins: [ChartDataLabels],
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.label;
                let value = context.formattedValue;
                let percentage = (value * 100 ).toFixed(2) + '%';
                return label + ": " + percentage;
              }
            }
          },
          datalabels: {
            formatter: (value, ctx) => {
              if (value < 0.10) { return "" }

              let percentageFormatted = (value*100).toFixed(0)+"%";
              let label = ctx.chart.data.labels[ctx.dataIndex]
              return `${label}\n${percentageFormatted}`;
            },
            color: '#000',
          },
          legend: {
            display: false
          }
        }
      }
    }
  };

  initializeMonthlyChartData(data) {
    return {
      labels: data.labels,
      datasets: [{
        type: 'line',
        label: 'Orders',
        data: Object.values(data.orders_opened_by_months),
        backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
        ],
        borderColor: [
            'rgba(255, 99, 132, 1)',
        ],
        yAxisID: 'y'
      },
      {
        type: 'line',
        label: 'Closings',
        data:  Object.values(data.orders_closed_by_months),
        backgroundColor: [
            'rgba(54, 162, 235, 0.2)',
        ],
        borderColor: [
            'rgba(54, 162, 235, 1)',
        ],
        yAxisID: 'y'
      },
      {
        type: 'bar',
        label: 'Income',
        data: Object.values(data.revenue_by_months),
        backgroundColor: [
            'rgba(54, 235, 78, 0.2)',
        ],
        borderColor: [
            'rgba(54, 235, 78, 1)',
        ],
        borderWidth: 1,
        yAxisID: 'y1'
      }]
    };
  }

  initializeMonthlyChartWithBarsData(data) {
    return {
      labels: data.labels,
      datasets: data.datasets.map(set => {
        return {
          type: set.type,
          label: set.label,
          data: set.values,
          backgroundColor: [
              set.color,
          ],
          borderColor: [
              set.color,
          ],
          borderWidth: set.width,
          yAxisID: 'y1'
        }
      })

    };
  }

  initializeMonthlyChartWithBarsConfig(data) {
    return {
      data: data,
      type: 'bar',
      options: {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        elements: {
          line: {
            // borderWidth: 3
          }
        },
        stacked: false,
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            display: false,
          }
        },
        scales: {
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            title: {
              text: 'Quantity',
              display: true,
              align: 'end',
            },
            // grid line settings
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
          },
        }
      },
    };
  };

  initializeMonthlyChartConfig(data) {
    return {
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        elements: {
          line: {
            // borderWidth: 3
          }
        },
        stacked: false,
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            display: false,
          }
        },
        scales: {
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            title: {
              text: 'Orders & Closings',
              display: true,
              align: 'end',
            }
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            title: {
              text: 'Income',
              display: true,
              align: 'end',
            },
            ticks: {
              callback: function(value, index, ticks) {
                return '$' + value;
              },
            },
            // grid line settings
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
          },
        }
      },
    };
  };

  initializeLineChartData(data) {
    let initializedDatasets = []
    data.datasets.forEach(set => {
      initializedDatasets.push(this.initializeLineChartDataset(set))
    })
    return {
      labels: data.labels,
      datasets: initializedDatasets
    };
  }

  initializeLineChartDataset(set) {
    return {
      type: "line",
      label: set.label,
      data: Object.values(set.values),
      fill: false,
      borderColor: set.border_color,
      tension: 0.1
    }
  }

  initializeLineChartConfig(data) {
    return {
      type: 'line',
      data: data,
      options: {
        plugins: {
          title: {
            display: true,
            text: data.label,
          },
          legend: {
            display: false
          },
          datalabels: {
            display: false,
          }
        },
        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y: {
            grid: {
              display: false
            }
          }
        }
      }
    }
  }
}
