import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-tab"
export default class extends Controller {
  static targets = [ "selectBox", "tabs" ]

  change(event) {
    this.hideTabs()
    this.showTab(event.target.selectedIndex)
  }

  hideTabs() {
    this.tabsTargets.forEach(option => {
      option.classList.remove("show")
      option.classList.remove("active")
    })
  }

  showTab(tabIndex) {
    let tabElement = this.tabsTargets[tabIndex]
    tabElement.classList.add("show")
    tabElement.classList.add("active")
  }

}
