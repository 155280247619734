$(document).on('turbo:load', function() {
  // Add clients as Parties
  $( "[data-addClientParty]" ).autocomplete({
    source: function( request, response ) {
      $.ajax({
        url: $("#new_client.simple_form").attr('action'),
        dataType: "json",
        data: {
          search: {
            term: request.term,
            search_for: "name",
            no_filter: true
          }
        },
        success: function( data ) {
          response( data );
        }
      });
    },
    minLength: 2,
    delay: 100,
    autoFocus: true,
    select: function(event, ui) {
      event.preventDefault();
      addFoundClient($(this), ui.item)
      $(this).val(null)
    },
    focus: function(event, ui) {
      event.preventDefault();
    }
  });

  $( "[data-addClientWithUserParty]" ).autocomplete({
    source: function( request, response ) {
      $.ajax({
        url: $("#new_client.simple_form").attr('action'),
        dataType: "json",
        data: {
          search: {
            term: request.term,
            search_for: "name",
            is_user: true,
            user_role: this.element.data('userrole')
          }
        },
        success: function( data ) {
          response( data );
        }
      });
    },
    minLength: 2,
    delay: 100,
    autoFocus: true,
    select: function(event, ui) {
      event.preventDefault();
      addFoundClient($(this), ui.item)
      $(this).val(null)
    },
    focus: function(event, ui) {
      event.preventDefault();
    }
  });

  $(document).on("click", '[data-removeNewClient]', function() {
    $(this).parent().remove()
  })

  function addFoundClient(element, item) {
    let memberNumber = countClientsByKind(element.attr('name'))
    let parentModelName = element.attr('data-parentmodel')
    let pluralParentModel = element.attr('data-pluralparentmodel')
    let relationshipName = element.attr('data-relationshipname')
    let clientIdHiddenFieldHtml = `<input type="hidden" id="clients_${pluralParentModel}_id" name="${parentModelName}[${relationshipName}_attributes][${memberNumber}][client_id]" value="${item.value}" />`
    element.siblings('.ul.list-group').append(clientIdHiddenFieldHtml)
    $("[data-element-target").trigger("click")() // Quick way to use the stimulus element_controller & refresh the form
  }

  function countClientsByKind(kind) {
    return $(`[data-clientkind='${kind}']`).length
  }

  $("[data-save='client']").click(function() {
    let formData = {}
    let formArray = $("[data-newclientform").serializeArray()
    formArray.forEach((item) => {
      formData[item.name] = item.value
    })
    $.ajax({
      url: $("[data-newclientform")[0].action,
      method: "post",
      dataType: "json",
      data: formData,
      success: function( response ) {
        addCreatedClientSuccessMessage(response)
	      let modalElement = document.getElementById('createClientModal')
        let modal = bootstrap.Modal.getInstance(modalElement);
        modal.hide();
      },
      error: function(error) {
        addErrorsToClientForm(error.responseJSON)
      }
    });
  })

  function addErrorsToClientForm(errorObject) {
    $('[data-clientfeedback]').each(function() {
      $(this).html(errorObject[$(this).attr('data-clientfeedback')] || "")
    })
  }

  function addCreatedClientSuccessMessage(client) {
    let html = `
      <div class="alert alert-success alert-dismissible fade show" role="alert" data-clientsuccessalert='true'>
        Successfully added ${client.name}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    `
    $("[data-partiesalerts]").append(html)
  }

  // Form submit is spinner
  $("[data-searchFormSpinner]").hide()
  $(".fa-search").removeClass('d-none')

  $( "[data-searchForm]").submit(function() {
    $(this).find("[data-searchFormSpinner]").show()
    $(this).find(':submit').prop('disabled', true)
    $(this).find(".fa-search").hide()
  })

});
