import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal-action-link"

//Calls the modal open, but also calls the action from the link or button, without preventing it.
//This is useful for mailto: links or similar. It calls the mailto: action and also opens modal
//This could also be useful for future implementations, adding actions before/after the bootstrap modal call.
export default class extends Controller {
  static targets = [ "modal" ]

  callActionAndOpenModal(event) {
    new bootstrap.Modal(this.modalTarget).show()
  }
}
