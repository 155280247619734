$(document).on('turbo:load', function() {
  // https://stackoverflow.com/questions/16095080/how-to-toggle-twitter-bootstrap-tab-panes-with-select-option

  $('#trackingComparisonSelect').on('change', function (e) {
    let tabElement = $('#trackingComparisonTabs li a').eq($(this).val())[0];
    let tab = bootstrap.Tab.getOrCreateInstance(tabElement)
    tab.show();
  });
  $('#trackingComparisonSelect').trigger("change");

  $('#averagesSelect').on('change', function (e) {
    let tabElement = $('#averagesTabs li a').eq($(this).val())[0];
    let tab = bootstrap.Tab.getOrCreateInstance(tabElement)
    tab.show();
  });
  $('#averagesSelect').trigger("change");

  $('.select-submit').on('change', function (e) {
    if (this.value == 'date_range') {
      return
    }
      $("[data-searchFormSpinner]").show()
      this.form.submit()
  })

  $('.kpi-time-frame-select').on('change', function (e) {
    if (this.value == 'date_range') {
      $('.kpi-dates').removeClass('d-none')
      $('.kpi-date-picker').prop( "disabled", false );
    } else {
      $('.kpi-dates').addClass('d-none')
      $('.kpi-date-picker').prop( "disabled", true );
    }
  })

  $("[data-get-file-numbers='true' ]").on("shown.bs.popover", function () {
    let self = this
    let popoverBody = $(".popover-body");
    let startDate = $(this).data("start-date")
    let endDate = $(this).data("end-date")
    let kind = $(this).data("kind")
    let userId = $(this).data("user-id")
    $.ajax({
      url: '/reports/annual',
      type: "GET",
      dataType: "json",
      data: {
        report: {
          start_date: startDate,
          end_date: endDate,
          user_id: userId
        },
        kind: kind
      },
      success: function (result) {
        popoverBody.html(result.html);
        $(self).popover("update");
      },
      error: function (error) {
        popoverBody.html(`<em class='text-danger'>Something went wrong.</em>`);
      }
    });
  });
  $("[data-get-file-numbers='true' ]").click(function (e) {
    $(this).popover({
      title: "Order Numbers",
      placement: "right",
      html: true,
      content: `
          <span class='spinner-border spinner-border-sm ms-2'  
            data-searchformspinner='true' role='status' style='display: none;'>
            <span class='sr-only'>Loading...</span>
          </span>
        `
    });
    $(this).popover('show');
  })
});
