/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)

import Rails from "@rails/ujs";
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage";
require("@popperjs/core")
import "bootstrap";
import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap;

document.addEventListener("turbo:load", () => {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl)
  })

  var modalTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="modal"]'))
  var modalList = popoverTriggerList.map(function(modalTriggerEl) {
      return new bootstrap.Modal(modalTriggerEl)
  })
})

import "../controllers"
import "jquery";
import "jquery-ui"
import "@nathanvda/cocoon"
import "../channels"
import "../custom/add-to-home"
import "../custom/cable"
import "../custom/reports"
import "../custom/cable"
import "../custom/client_connections"
import "../custom/clients"
import "stylesheets/application";
import "@fortawesome/fontawesome-free/css/all";

Rails.start()
ActiveStorage.start()
