$(document).on('turbo:load', function() {

  $("[data-save='client_connection_by_mail_to'],[data-save='client_connection_by_phone_to']").click(function(event) {
    let form = $(event.target).closest('[data-newClientConnectionForm]')
    let href = event.target.href
    let successCallback = createClientConnectionByLinktoSuccessCallback
    let errorCallback = createClientConnectionByLinktoErrorCallback
    createClientConnection(form, 'link', successCallback, errorCallback)
  })

  $("[data-save='client_connection']").click(function(event) {
    let form = $("[data-newClientConnectionForm")
    let successCallback = createClientConnectionByFormSuccessCallback
    let errorCallback = createClientConnectionByFormErrorCallback
    createClientConnection(form, '', successCallback, errorCallback);
  })

  function createClientConnection(form, origin, successCallback, onErrorCallback) {
    let formData = {}
    let url = form.attr("action")
    let formArray = form.serializeArray()

    formArray.forEach((item) => {
      formData[item.name] = item.value
    })
    formData.origin = origin

    $.ajax({
      url: url,
      method: "post",
      dataType: "json",
      data: formData,
      success: function( response ) {
        successCallback(response)
      },
      error: function(error) {
        onErrorCallback(error)
      }
    });
  }

  function createClientConnectionByFormSuccessCallback(response) {
    let modalElement = document.getElementById('createClientConnectionModal')

    if (modalElement) {
      addCreatedClientConnectionSuccessMessage($("[data-contactsalerts]"))
      addCreatedClientConnectionToList(response)
      let modal = bootstrap.Modal.getInstance(modalElement);
      modal.hide();
    }
  }

  function createClientConnectionByLinktoSuccessCallback(href) {
    window.location.reload()
  }

  function createClientConnectionByFormErrorCallback() {
    addErrorsToClientConnectionForm(error.responseJSON)
  }

  function createClientConnectionByLinktoErrorCallback(response) {
    window.location.reload();
  }

  function addErrorsToClientConnectionForm(errorObject) {
    // not currently working
    $('[data-client-contactfeedback]').each(function() {
      $(this).html(errorObject[$(this).attr('data-client-contactfeedback')] || "")
    })
  }

  function addCreatedClientConnectionSuccessMessage() {
    let html = `
      <div class="alert alert-success alert-dismissible fade show" role="alert" data-client-contactsuccessalert='true'>
        Successfully added client connection
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
    `
    $("[data-contactsalerts]").append(html)
  }

  function addCreatedClientConnectionToList(response) {
    let html = `
      <tr>
        <td><a href="${response.edit_path}">${response.formatted_contacted_at}</a></td>
        <td>${response.client_connection_type.name}</td>
        <td>$${response.expense_grand_total}</td>
        <td>${response.user.name}</td>
        <td>${response.notes}</td>
      </tr>
    `
    $("[data-client-contacts]").prepend(html)

  }

});
