import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "prospectDays",
    "clientDays",
    "clientClosedOrders",
    "prospectDaysDisplay",
    "clientClosedOrdersDisplay",
    "clientClosedOrdersDisplay2",
    "clientClosedOrdersDisplay3",
    "clientDaysDisplay"
  ]

  connect() {
    this.updateDisplays()
  }

  update() {
    this.updateDisplays()
  }

  updateDisplays() {
    const prospectDays = this.prospectDaysTarget.value;
    const clientDays = this.clientDaysTarget.value;
    const clientClosedOrders = this.clientClosedOrdersTarget.value;

    this.prospectDaysDisplayTarget.value = prospectDays; // Update the display for prospect days
    this.clientDaysDisplayTarget.value = clientDays; // Update the display for client days
    this.clientClosedOrdersDisplayTarget.value = clientClosedOrders;
    this.clientClosedOrdersDisplay2Target.value = clientClosedOrders;
    this.clientClosedOrdersDisplay3Target.value = clientClosedOrders;
  }
}
