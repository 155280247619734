// Due to Turbo, this variable must be global
let deferredPrompt = null;

$(document).on('turbo:load', function() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch(error => {
          console.error('Service Worker registration failed:', error);
        });
    });
  }

  if (deferredPrompt !== null && !isAppInstalled()) {
    displayAddToHomeButton()
  }

  const installLink = document.getElementById('install');

  if (isIos()) {
    // iOS doesn't support the beforeinstallprompt event
    // neither allow us to isntall the app automatically

    if (!isAppInstalled()) {
      // When not running the installed app, always display
      // the install link, iOS doens't support this detection
      displayAddToHomeButton();

      // For iOS, we display a modal with the instructions
      // to install the app
      installLink.addEventListener('click', () => {
        new bootstrap.Modal($('#add-to-ios-home')).show()
      });
    }
  } else {
    // This event is only trigered if the app is not installed yet.
    // But, just in case, I'm keeping the extra validation to avoid issues.
    if (!isAppInstalled()) {
      // Android and Desktop applications handle the beforeinstallprompt command
      // when they detect a PWA.
      // In that case, we display the AddLink
      window.addEventListener('beforeinstallprompt', (e) => {
        deferredPrompt = e;
        displayAddToHomeButton();
      });

      window.addEventListener('appinstalled', (event) => {
        // Necessary to hide the button on the next page load
        deferredPrompt = null;
        hideAddToHomeButton();
      });

      // When clicking on isntall button, the user is able to "install"
      // the app by adding an icon to his home page
      installLink.addEventListener('click', async () => {
        if (deferredPrompt !== null) {
          deferredPrompt.prompt();
        } else {
          hideAddToHomeButton()
        }
      });
    }
  }

  // Check if the app is installed (PWA criteria may vary)
  // This display mode is set on manifest.json file
  function isAppInstalled() {
    return ('standalone' in navigator && navigator.standalone) ||
           (window.matchMedia('(display-mode: standalone)').matches);
  }

  function displayAddToHomeButton() {
    $('.add-to-home').addClass("show");
    $('.add-to-home').removeClass("d-none");
  }

  function hideAddToHomeButton() {
    $('.add-to-home').addClass("d-none");
    $('.add-to-home').removeClass("show");
  }

  function isIos () {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/i.test(userAgent);
  }
})

